<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import CashbackBalance from '/~/components/app/header/app-header-cashback-balance.vue'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import Membership from '/~/components/app/header/app-header-membership.vue'
import SavedToDate from '/~/components/app/header/app-header-saved-to-date.vue'
import { useHeaderTopMenu } from '/~/components/app/header/components/header-top-menu/use-header-top-menu'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'
import HeaderTopMenu from './components/header-top-menu/header-top-menu.v1.vue'

const { activeTopMenuItem } = useHeaderTopMenu()
const { cmsConfig } = useCms()
const { providerTitle } = useProvider()

const logoWidth = computed(() => cmsConfig.value['header-logo-max-width'])
</script>

<template>
  <header class="bg-light">
    <h1 class="sr-only">{{ providerTitle }}</h1>
    <portal-target name="app-header" slim />

    <div
      class="relative flex h-[88px] items-center justify-between px-[15px] sm:px-6"
    >
      <portal-target name="app-header-menu" slim />

      <app-header-logo
        class="mr-auto py-2.5"
        :max-width="ui.mobile ? 66 : logoWidth"
      />

      <a class="sr-only" href="#main-content">Skip to main content</a>
      <template v-if="!ui.mobile">
        <saved-to-date class="mx-8" />
      </template>
      <template v-if="!ui.mobile">
        <cashback-balance class="mr-8" />
      </template>

      <membership v-if="!ui.mobile" class="mx-2.5 xs:ml-0 xs:mr-[30px]" />

      <header-top-menu :active-item="activeTopMenuItem" />
    </div>
    <portal-target name="header-bottom" slim />
  </header>
</template>
