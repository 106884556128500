<script>
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useUser } from '/~/composables/user'

export default {
  name: 'header-membership',
  components: {
    BaseAction,
    BaseIcon,
  },
  setup() {
    const { tierBadgeAction, showTierBadge } = useCms()
    const { user } = useUser()

    return {
      showTierBadge,
      tierBadgeAction,
      user,
      ui,
    }
  },
}
</script>

<template>
  <div v-if="showTierBadge">
    <base-action
      v-if="tierBadgeAction"
      v-bind="tierBadgeAction"
      appearance="link"
    >
      <span
        class="flex h-10 w-auto items-center justify-center whitespace-nowrap rounded-md bg-primary px-2.5 text-sm font-semibold text-white xs:h-12 xs:min-w-40 xs:text-xl"
      >
        <base-icon
          v-if="!ui.mobile"
          svg="v2/custom/medal"
          class="mr-[5px]"
          :size="24"
        />

        <span class="capitalize">
          {{ user.membershipName }}
        </span>
      </span>
    </base-action>
    <span
      v-else
      class="flex h-10 w-auto items-center justify-center whitespace-nowrap rounded-md bg-primary px-2.5 text-sm font-semibold text-white xs:h-12 xs:min-w-40 xs:text-xl"
    >
      <base-icon
        v-if="!ui.mobile"
        svg="v2/custom/medal"
        class="mr-[5px]"
        :size="24"
      />

      <span class="capitalize">
        {{ user.membershipName }}
      </span>
    </span>
  </div>
</template>
