<script>
import { useCms } from '/~/composables/cms/use-cms'
import DesktopFooterV2 from './desktop-footer.v2.vue'
import DesktopFooterV3 from './desktop-footer.v3.vue'

export default {
  name: 'desktop-footer',
  functional: true,
  render(h, ctx) {
    const { isFooterVersionV2, isFooterVersionV3 } = useCms()

    let component

    if (isFooterVersionV3.value) {
      component = DesktopFooterV3
    } else if (isFooterVersionV2.value) {
      component = DesktopFooterV2
    } else {
      return null
    }

    return h(component, ctx)
  },
}
</script>
