<script lang="ts" setup>
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useEwallet } from '/~/composables/ewallet'

const { showCashbackBalance } = useCms()
const { ewalletBalance, fetchingEwallet } = useEwallet()
</script>

<template>
  <router-link
    v-if="showCashbackBalance"
    class="flex items-center"
    :to="{ hash: '#profile-home' }"
  >
    <span
      class="mr-2.5 flex h-[30px] w-[30px] flex-none items-center justify-center rounded-lg bg-primary text-white"
    >
      <base-icon svg="v2/custom/cash" :size="21" />
    </span>
    Available funds balance
    <base-loader v-if="fetchingEwallet" class="ml-1.5" size="sm" />
    <b v-else class="ml-1.5">
      {{ formatDollar(ewalletBalance) }}
    </b>
  </router-link>
</template>
