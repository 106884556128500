<script>
import { computed } from 'vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'desktop-footer-v3',
  components: {
    BaseAction,
  },
  setup() {
    const {
      getMenuItems,
      isDarkThemeForFooter,
      isLightThemeForFooter,
      cmsConfig,
    } = useCms()
    const { providerTitle } = useProvider()

    const menuItems = computed(() => {
      return getMenuItems('footer')
    })
    const logoImageSrc = computed(() => {
      return isLightThemeForFooter.value
        ? '/images/bupa/logo-horizontal-dark.svg'
        : '/images/bupa/logo-horizontal-light.svg'
    })

    const logoAlt = computed(
      () =>
        cmsConfig.value['footer-alt'] ||
        cmsConfig.value['logo-alt'] ||
        `${providerTitle.value} Logo`
    )

    return {
      menuItems,
      logoImageSrc,
      isDarkThemeForFooter,
      isLightThemeForFooter,
      logoAlt,
    }
  },
}
</script>

<template>
  <footer
    id="footer"
    class="flex h-[88px] flex-shrink-0 items-center justify-center px-2.5"
    :class="{
      'bg-black': isDarkThemeForFooter,
      'bg-white': isLightThemeForFooter,
    }"
  >
    <div class="ml-[88px] mr-auto w-32 flex-shrink-0">
      <img :src="logoImageSrc" :alt="logoAlt" />
    </div>
    <nav role="navigation">
      <ul class="flex divide-x text-xs">
        <li v-for="(item, idx) in menuItems" :key="item.id || idx">
          <base-action v-bind="item.action || {}" :label="item.label">
            <span
              class="px-6 hover:underline"
              :class="{
                'text-white': isDarkThemeForFooter,
                'text-black': isLightThemeForFooter,
              }"
            >
              {{ item.label }}
            </span>
          </base-action>
        </li>
      </ul>
    </nav>
    <div class="ml-auto mr-[88px] flex-shrink-0" />
  </footer>
</template>
