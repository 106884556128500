<script>
import BaseAction from '/~/components/base/action/base-action.vue'
import { useAuth } from '/~/composables/auth'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

export default {
  name: 'desktop-footer-v2',
  components: {
    BaseAction,
  },
  setup() {
    const { logout } = useAuth()
    const { getMenuItems } = useCms()
    const { isLogoutEnabled } = useProvider()
    const { user } = useUser()

    return {
      logout,
      getMenuItems,
      isLogoutEnabled,
      user,
    }
  },
  computed: {
    menuItems() {
      return this.getMenuItems('footer')
    },
  },
}
</script>

<template>
  <footer
    id="footer"
    class="flex h-12 flex-shrink-0 items-center justify-end bg-footer px-6 text-footer"
  >
    <ul class="flex items-center divide-x-[1.5px] font-bold">
      <li v-for="(item, idx) in menuItems" :key="item.id || idx">
        <base-action
          v-analytics:click="{
            pageGroup: 'Footer',
            cta: item.label,
          }"
          v-bind="item.action || {}"
        >
          <span class="block px-8 text-white hover:underline">
            {{ item.label }}
          </span>
        </base-action>
      </li>
      <li v-if="isLogoutEnabled && user.authorized">
        <base-action
          v-analytics:click="{
            pageGroup: 'Footer',
            cta: 'Logout',
          }"
          class="cursor-pointer pl-8 text-white hover:underline"
          type="action"
          value="logout"
          @click="logout"
        >
          Logout
        </base-action>
      </li>
    </ul>
  </footer>
</template>
