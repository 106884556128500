<script setup lang="ts">
import { computed } from 'vue'
import ui from '/~/core/ui'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import { useHeaderTopMenu } from '/~/components/app/header/components/header-top-menu/use-header-top-menu'
import PointsWithdraw from '/~/components/points/points-withdraw.vue'
import { useCms } from '/~/composables/cms/use-cms'
import HeaderTopMenu from './components/header-top-menu/header-top-menu.v5.vue'

const { activeTopMenuItem } = useHeaderTopMenu()

const { cmsConfig } = useCms()
const logoWidth = computed(() => cmsConfig.value['header-logo-max-width'])
</script>

<template>
  <header class="border-b bg-light sm:border-none">
    <portal-target name="app-header" slim />
    <div
      class="relative flex h-14 items-center justify-between px-[15px] sm:h-auto sm:px-6 md:h-[88px]"
    >
      <portal-target name="app-header-menu" slim />
      <app-header-logo
        class="mr-auto py-2.5"
        :max-width="ui.mobile ? 92 : logoWidth"
      />
      <points-withdraw v-if="!ui.mobile" class="ml-auto mr-5" />
      <header-top-menu :active-item="activeTopMenuItem" />
    </div>
    <portal-target name="header-bottom" slim />
  </header>
</template>
