<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import ui from '/~/core/ui'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import { useHeaderTopMenu } from '/~/components/app/header/components/header-top-menu/use-header-top-menu'
import Points from '/~/components/points/points.v2.vue'
import { useCms } from '/~/composables/cms/use-cms'
import RecroomPoints from './app-header-rec-points.vue'
import HeaderTopMenu from './components/header-top-menu/header-top-menu.v1.vue'

const { activeTopMenuItem } = useHeaderTopMenu()

const route = useRoute()
const { cmsConfig } = useCms()
const logoWidth = computed(() => cmsConfig.value['header-logo-max-width'])
const isRecroomActive = computed(() => route.path.includes('rec-room'))
</script>

<template>
  <header class="bg-light">
    <portal-target name="app-header" slim />

    <div
      class="relative flex h-[88px] items-center justify-between px-[15px] sm:px-6"
    >
      <portal-target name="app-header-menu" slim />
      <app-header-logo
        class="mr-auto py-2.5"
        :max-width="ui.mobile ? 92 : logoWidth"
      />

      <recroom-points
        v-if="isRecroomActive"
        class="hidden w-full justify-end md:flex"
        show-management
      />

      <points v-else-if="!ui.mobile" />

      <header-top-menu :active-item="activeTopMenuItem" />
    </div>
    <portal-target name="header-bottom" slim />
  </header>
</template>
